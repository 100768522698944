<template>

  <div>

    <form-wizard
      id="cartsWizard"
      ref="wizardRef"
      color="#7367F0"
      :title="null"
      :subtitle="null"
      class="steps-transparent mb-3"
      hide-buttons
      :start-index="startIndex"
      @on-change="(from, to) => onWizardTabChange(to)"
    >
      <!-- cart products -->
      <tab-content
        v-for="step in wizardSteps"
        :key="step.id"
        :title="$t(step.title) + (aggregates && (step.id in aggregates) ? ` (${aggregates[step.id]})` : '')"
        :icon="`feather ${step.icon}`"
        :route="$route.params.step !== step.id ? {name: 'abandonedCarts.list', params: {step: step.id}} : null"
      />
    </form-wizard>

    <!-- Filters -->
    <!--    <filters-->
    <!--      :customer-filter.sync="customerFilter"-->
    <!--      :status-filter.sync="phaseFilter"-->
    <!--    />-->

    <!-- Table Container Card -->
    <model-list-table
      :store-module-name="storeModuleName"
      :table-columns="tableColumns"
      :filters-list="filtersList"
      :query-params="getQueryParams"
      :add-action="false"
      :view-action="false"
      :edit-action="false"
      :delete-action="false"
      :extra-actions="[]"
      :is-sortable="false"
      @update:meta="meta => onMetaUpdate(meta)"
    >
      <template #pre_bulk_actions="{isSortingActive, isSomeRowsSelected, isAllRowsSelected, rowsSelected}">
        <b-button
          v-if="!isSortingActive"
          id="notify-records-btn"
          v-b-modal.send-notification-modal
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="info"
          class="mr-1"
          :disabled="!isSomeRowsSelected && !isAllRowsSelected"
          @click="preselectedEntities = rowsSelected.map(r => String(r.id))"
        >
          <feather-icon
            icon="BellIcon"
            class="mr-50"
          />
          <span
            v-t="`modules.abandoned_carts.actions.notify`"
            class="align-middle text-nowrap"
          />
        </b-button>
      </template>
      <template #actions="{item}">
        <b-button
          :id="`notify-${item.id}-btn`"
          v-ripple.400="'rgb(49, 49, 49, 0.15)'"
          v-b-modal.send-notification-modal
          variant="link"
          class="p-1 text-secondary btn-link"
          @click="preselectedEntities = [String(item.id)]"
        >
          <feather-icon
            icon="BellIcon"
            size="18"
          />
          <div
            v-t="`modules.abandoned_carts.actions.notify`"
            class="pt-50"
          />
        </b-button>
      </template>
    </model-list-table>

    <WizardModalSendNotification
      :is-abandoned-carts-reminder="true"
      selected-entity-type="CART"
      :selected-entity-ids="preselectedEntities"
    />
  </div>
</template>

<script>
import store from '@/store'
import { ref, onUnmounted, onMounted } from 'vue'
import { formatDatetime } from '@core/utils/filter'
import ModelListTable from '@/views/models/common/ModelListTable.vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import router from '@/router'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { BButton, VBToggle } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import WizardModalSendNotification
from '@/views/models/notification_campaigns/modals/send/WizardModalSendNotification.vue'
import cartStoreModule from '../cartStoreModule'

export default {
  components: {
    WizardModalSendNotification,
    BButton,
    FormWizard,
    TabContent,
    ModelListTable,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },

  methods: {
  },
  setup() {
    const STORE_MODULE_NAME = 'orders'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, cartStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const selectedStep = router.currentRoute.params.step
    const customerFilter = ref(null)
    const phaseFilter = ref(selectedStep)

    const { t } = useI18nUtils()
    const tableColumns = [
      {
        key: 'checkbox',
        label: '',
        sortable: false,
      },
      { key: 'id', sortable: false, label: 'ID' },
      {
        key: 'related_customer', sortable: true, label: 'modules.customers.s_c',
      },
      {
        key: 'cart_subtotal', sortable: true, label: 'modules.abandoned_carts.tableColumns.cart_amount',
      },
      {
        key: 'cart_step',
        sortable: true,
        label: 'modules.abandoned_carts.tableColumns.step',
        formatter: v => t(`modules.abandoned_carts.pages.${v}`),
      },
      // { key: 'reminder_notification_status', sortable: true, label: 'modules.abandoned_carts.tableColumns.reminder_notification_status' },
      {
        key: 'reminder_notifications_count',
        sortable: true,
        label: 'modules.abandoned_carts.tableColumns.reminder_notifications',
        // formatter: v => v ? t('modules.abandoned_carts.tableColumns.x_reminder_notifications', { count: v }).replace('{count}', v) : '-',
      },
      {
        key: 'created_at',
        label: 'Creation Date',
        formatter: v => formatDatetime(v),
        sortable: true,
      },
      {
        key: 'updated_at',
        label: 'Last Update',
        formatter: v => formatDatetime(v),
        sortable: true,
      },
      { key: 'actions' },
    ]

    const filtersList = [customerFilter, phaseFilter]
    const getQueryParams = () => ({
      customer_id: customerFilter.value,
      phase: phaseFilter.value,
    })

    const wizardRef = ref(null)
    onMounted(() => {
      wizardRef.value.activateAll()
    })

    const wizardSteps = [
      {
        id: 'products',
        title: 'modules.abandoned_carts.pages.products',
        icon: 'icon-shopping-cart',
      },
      {
        id: 'contact',
        title: 'modules.abandoned_carts.pages.contact',
        icon: 'icon-user',
      },
      {
        id: 'shipping_address',
        title: 'modules.abandoned_carts.pages.shipping_address',
        icon: 'icon-map-pin',
      },
      {
        id: 'shipping_method',
        title: 'modules.abandoned_carts.pages.shipping_method',
        icon: 'icon-truck',
      },
      {
        id: 'payment',
        title: 'modules.abandoned_carts.pages.payment',
        icon: 'icon-credit-card',
      },
      // {
      //   id: 'checkout',
      //   title: 'modules.abandoned_carts.pages.checkout',
      //   icon: 'icon-check-circle',
      // },
    ]

    const startIndex = wizardSteps.findIndex(step => step.id === selectedStep) || 0

    const onWizardTabChange = newValue => {
      if (newValue < 0) {
        return
      }
      phaseFilter.value = wizardSteps[newValue].id
    }

    const preselectedEntities = ref([])

    const aggregates = ref({})
    const onMetaUpdate = meta => {
      aggregates.value = meta.aggregates
    }

    return {
      wizardRef,
      wizardSteps,
      startIndex,

      // Extra Filters
      customerFilter,
      phaseFilter,

      // Table props
      tableColumns,
      storeModuleName: STORE_MODULE_NAME,
      filtersList,
      aggregates,
      preselectedEntities,
      getQueryParams,
      onWizardTabChange,
      onMetaUpdate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">

$gray-300: #dee2e6 !default;
$border-color: $gray-300 !default;

::v-deep .b-table {
  tbody {
    tr {
      //cursor: move;
    }
  }
}

.b-table {
  tbody {
    tr.ready-for-drag {
      transform: translateY(-4px);
      box-shadow: 0 3px 10px 0 $border-color;
      transition: all 0.2s;
    }
    tr {
      &:has(td .draggable-handle) {
        transform: translateY(-4px);
        box-shadow: 0 3px 10px 0 $border-color;
        transition: all 0.2s;
      }
    }
  }
}

.btn-link:hover {
  color: #5d6064 !important;
}

#cartsWizard {
  .wizard-tab-content {
    display: none;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
